import {defineStore} from 'pinia'

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: JSON.parse(localStorage.getItem('token')),
    }),
    getters: {
        isAuthenticated() {
            return Boolean(this.token)
        }
    },
    actions: {
        setToken(token) {
            this.token = token
            localStorage.setItem('token', JSON.stringify(token))
        },
        deleteToken() {
            this.token = null
            localStorage.removeItem('token')
        },
    },
})