<script setup>
import Breadcrumb from '~/components/ui/Breadcrumb'
import {useLayoutStore} from '~/store/layout'
const layout = useLayoutStore()
</script>
<template>
  <div class="wrapper">
    <LayoutSidebar></LayoutSidebar>
    <main class="main mt-4 p-0">
      <div class="container-fluid">
        <div class="mb-4" v-if="layout.title">
          <h1 class="header-title">{{ layout.title }}</h1>
          <Breadcrumb v-if="layout.breadcrumb.length" :items="layout.breadcrumb" class="pt-1"></Breadcrumb>
        </div>
        <slot/>
      </div>
    </main>
  </div>
</template>
