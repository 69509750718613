<script setup>
import SidebarNav from "./SidebarNav.vue";

const menu = [
  {text: 'Main', header: true},
  {text: 'Dashboard', icon: 'fa-home', to: '/'},
  {text: 'Courses', icon: 'fa-file-alt', to: '/courses'},
  {text: 'Checkouts', icon: 'fa-dollar', to: '/checkouts'},
  // {
  //   text: 'Content',
  //   icon: 'fa-file-alt',
  //   items: [
  //     {text: 'Courses', to: '/courses'},
  //     {text: 'Sections', to: '/sections'},
  //   ]
  // },
  {text: 'Sign out', icon: 'fa-sign-out', to: '/auth/signout'},
]
</script>
<template>
  <nav class="sidebar">
    <NuxtLink class="sidebar-brand" to="/">
      <img src="/static/img/logo.png" class="img-fluid mb-2" alt="gomobile">
    </NuxtLink>
    <div class="sidebar-content">
      <SidebarNav :items="menu"/>
    </div>
  </nav>
</template>
