<script setup>
</script>
<template>
  <div class="container h-100">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-4 mx-auto d-table h-100">
        <div class="d-table-cell align-top pt-6">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>
