// https://vee-validate.logaretm.com/v4/guide/global-validators#vee-validaterules
import { defineRule } from 'vee-validate';
import { required, email, integer, numeric, max} from '@vee-validate/rules';

export default defineNuxtPlugin(() => {
    defineRule('required', required)
    defineRule('email', email)
    defineRule('integer', integer)
    defineRule('numeric', numeric)
    defineRule('max', max)
});
