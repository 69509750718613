import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as signoutThcUhmG1tRMeta } from "/app/pages/auth/signout.vue?macro=true";
import { default as checkoutsoAToC7C5YMMeta } from "/app/pages/checkouts.vue?macro=true";
import { default as indexMV6NgStK8mMeta } from "/app/pages/courses/[id]/index.vue?macro=true";
import { default as _91lessonid_93wMwXWytqIiMeta } from "/app/pages/courses/[id]/lesson/[lessonid].vue?macro=true";
import { default as sectionsQaa0TeX0ZLMeta } from "/app/pages/courses/[id]/sections.vue?macro=true";
import { default as _91id_93xlD0x4nWuKMeta } from "/app/pages/courses/[id].vue?macro=true";
import { default as index7c5fwVIKVsMeta } from "/app/pages/courses/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexP0q8KYV1WMMeta } from "/app/pages/search/index.vue?macro=true";
import { default as sectionsPCv1ScaxJ7Meta } from "/app/pages/sections.vue?macro=true";
export default [
  {
    name: loginikXFF2hTKwMeta?.name ?? "auth-login",
    path: loginikXFF2hTKwMeta?.path ?? "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    alias: loginikXFF2hTKwMeta?.alias || [],
    redirect: loginikXFF2hTKwMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: signoutThcUhmG1tRMeta?.name ?? "auth-signout",
    path: signoutThcUhmG1tRMeta?.path ?? "/auth/signout",
    meta: signoutThcUhmG1tRMeta || {},
    alias: signoutThcUhmG1tRMeta?.alias || [],
    redirect: signoutThcUhmG1tRMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/signout.vue").then(m => m.default || m)
  },
  {
    name: checkoutsoAToC7C5YMMeta?.name ?? "checkouts",
    path: checkoutsoAToC7C5YMMeta?.path ?? "/checkouts",
    meta: checkoutsoAToC7C5YMMeta || {},
    alias: checkoutsoAToC7C5YMMeta?.alias || [],
    redirect: checkoutsoAToC7C5YMMeta?.redirect || undefined,
    component: () => import("/app/pages/checkouts.vue").then(m => m.default || m)
  },
  {
    path: _91id_93xlD0x4nWuKMeta?.path ?? "/courses/:id()",
    children: [
  {
    name: indexMV6NgStK8mMeta?.name ?? "courses-id",
    path: indexMV6NgStK8mMeta?.path ?? "",
    meta: indexMV6NgStK8mMeta || {},
    alias: indexMV6NgStK8mMeta?.alias || [],
    redirect: indexMV6NgStK8mMeta?.redirect || undefined,
    component: () => import("/app/pages/courses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91lessonid_93wMwXWytqIiMeta?.name ?? "courses-id-lesson-lessonid",
    path: _91lessonid_93wMwXWytqIiMeta?.path ?? "lesson/:lessonid()",
    meta: _91lessonid_93wMwXWytqIiMeta || {},
    alias: _91lessonid_93wMwXWytqIiMeta?.alias || [],
    redirect: _91lessonid_93wMwXWytqIiMeta?.redirect || undefined,
    component: () => import("/app/pages/courses/[id]/lesson/[lessonid].vue").then(m => m.default || m)
  },
  {
    name: sectionsQaa0TeX0ZLMeta?.name ?? "courses-id-sections",
    path: sectionsQaa0TeX0ZLMeta?.path ?? "sections",
    meta: sectionsQaa0TeX0ZLMeta || {},
    alias: sectionsQaa0TeX0ZLMeta?.alias || [],
    redirect: sectionsQaa0TeX0ZLMeta?.redirect || undefined,
    component: () => import("/app/pages/courses/[id]/sections.vue").then(m => m.default || m)
  }
],
    name: _91id_93xlD0x4nWuKMeta?.name ?? undefined,
    meta: _91id_93xlD0x4nWuKMeta || {},
    alias: _91id_93xlD0x4nWuKMeta?.alias || [],
    redirect: _91id_93xlD0x4nWuKMeta?.redirect || undefined,
    component: () => import("/app/pages/courses/[id].vue").then(m => m.default || m)
  },
  {
    name: index7c5fwVIKVsMeta?.name ?? "courses",
    path: index7c5fwVIKVsMeta?.path ?? "/courses",
    meta: index7c5fwVIKVsMeta || {},
    alias: index7c5fwVIKVsMeta?.alias || [],
    redirect: index7c5fwVIKVsMeta?.redirect || undefined,
    component: () => import("/app/pages/courses/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexP0q8KYV1WMMeta?.name ?? "search",
    path: indexP0q8KYV1WMMeta?.path ?? "/search",
    meta: indexP0q8KYV1WMMeta || {},
    alias: indexP0q8KYV1WMMeta?.alias || [],
    redirect: indexP0q8KYV1WMMeta?.redirect || undefined,
    component: () => import("/app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: sectionsPCv1ScaxJ7Meta?.name ?? "sections",
    path: sectionsPCv1ScaxJ7Meta?.path ?? "/sections",
    meta: sectionsPCv1ScaxJ7Meta || {},
    alias: sectionsPCv1ScaxJ7Meta?.alias || [],
    redirect: sectionsPCv1ScaxJ7Meta?.redirect || undefined,
    component: () => import("/app/pages/sections.vue").then(m => m.default || m)
  }
]