import {defineStore} from 'pinia'

export const useLayoutStore = defineStore('layout', {
    state: () => ({
        title: "",
        subtitle: "",
        breadcrumb: []
    }),
    actions: {
        resetHeader() {
            this.title = ""
            this.subtitle = ""
            this.breadcrumb = []
        },
        setHeader({title, subtitle, breadcrumb}) {
            this.title = title || ''
            this.subtitle = subtitle || ''
            this.breadcrumb = breadcrumb.length ? breadcrumb : []
        }
    },
})