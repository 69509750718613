<script setup>
import {ref} from 'vue'

const {item} = defineProps({
  item: {
    type: Object,
    required: true
  },
})
const isOpen = ref(item.items.reduce((c, e) => c || e?.to === useRoute().path, false))
</script>
<template>
  <a class="sidebar-link" href="#" @click.prevent="() => isOpen = !isOpen" data-bs-toggle="collapse"

  :class="{'collapsed': !isOpen}"
  >
    <i v-if="item.icon" class="align-middle me-2 fas fa-fw" :class="item.icon"></i>
    <span class="align-middle">{{ item.text }}</span>
  </a>
  <ul class="sidebar-dropdown list-unstyled collapse" :class="{'show': isOpen}">
    <li
        v-for="(link, index) in item.items" :key="index"
        class="sidebar-item"
        :class="{'active': link.to === useRoute().path}"
    >
      <NuxtLink class="sidebar-link" :to="link.to">
          {{ link.text }}
      </NuxtLink>
    </li>
  </ul>
</template>